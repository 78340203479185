import React from "react";

import Collapse from "../../../../../../components/common/v2/collapse";

import ModalHardStop from "../modals/hard-stop";
import ModalUpdateBlending from "../modals/update-blending";
import ModalCheckProductStock from "../modals/check-product-stock";
import ModalEditOrder from "../modals/edit-order";
import ModalCheckKeys from "../modals/check-keys";
import ModalUpdateStatus from "../modals/update-status";
import ModalCreateAllocation from "../modals/create-allocation";
import ModalGetBuffered from "../modals/get-buffered";
import CheckPermission, {
  StaticPermission,
} from "../../../../../../constants/permission";

const ActionDeck = ({ data, userScope, userPermission }) => {
  return (
    <Collapse disabled title="Action Deck">
      <div className="w-100 d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-wrap gap-2">
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Approve"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Refuse"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Pause"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Resume"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Revoke"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Refund"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Cancel"
            />
            <ModalUpdateStatus
              order={data.order}
              userScope={userScope}
              type="Close"
            />
            <ModalEditOrder data={data} userScope={userScope} />
            <ModalUpdateBlending data={data} userScope={userScope} />
            <ModalHardStop data={data} userScope={userScope} />
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <ModalCheckKeys data={data} />
            <ModalCheckProductStock data={data} />
          </div>
        </div>

        {CheckPermission(StaticPermission.Order.Allocation, userPermission) ? (
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-2">
              <ModalCreateAllocation data={data} userScope={userScope} />
              <ModalGetBuffered data={data} userScope={userScope} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Collapse>
  );
};

export default ActionDeck;
