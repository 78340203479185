import React from "react";
import classNames from "classnames";
import { OrderPaidStatus } from "../../../../../../constants/defaults";
import OrderPaymentStatus from "../../../../../../components/common/v2/status/order-payment-status";

const FinanceDetailRowHighlighted = ({ data, title }) => (
  <span className="d-flex justify-content-between" style={{ fontSize: 14 }}>
    <b className="dark-slate-blue">{title}</b>
    <span className="cloudy-blue">{data}</span>
  </span>
);

const FinanceDetailRow = ({ compare = 0, data, invalid, title }) => (
  <span className="d-flex justify-content-between" style={{ fontSize: 14 }}>
    <b className="cloudy-blue">{title}</b>
    <span
      className={classNames(
        compare > 0
          ? "up-text-color"
          : compare < 0
          ? "down-text-color"
          : "cloudy-blue"
      )}
    >
      {invalid ? "-" : data}
    </span>
  </span>
);

const FinanceDetailPaymentStatusRow = ({ data, title }) => {
  return (
    <span className="d-flex justify-content-between" style={{ fontSize: 14 }}>
      <b className="dark-slate-blue">{title}</b>
      <OrderPaymentStatus data={data} />
    </span>
  );
};

const RowSeparator = () => <div className="d-flex border-bottom"></div>;

const calculateAvailableQuantity = (order) => {
  const totalQuantity =
    order?.CompletedQuantity + parseInt(order?.BufferedQuantity) <=
    order?.Quantity
      ? order?.CompletedQuantity + parseInt(order?.BufferedQuantity)
      : 0;

  return totalQuantity - order?.DeliveredQuantity;
};

const calculateRemainingQuantity = (order) => {
  const totalQuantity =
    order?.CompletedQuantity + order?.BufferedQuantity <= order?.Quantity
      ? order?.CompletedQuantity + order?.BufferedQuantity
      : order?.Quantity;

  return order?.Offer.OfferQuantity - totalQuantity;
};

const FinanceDetailQuantity = ({ order }) => (
  <div className="d-flex gap-4">
    <div className="w-50 d-flex flex-column gap-2">
      <FinanceDetailRow
        data={order.Offer.OfferQuantity}
        title="Offer Quantity"
      />
      <FinanceDetailRow
        data={calculateAvailableQuantity(order)}
        title="Available Quantity"
      />
      <FinanceDetailRow
        data={order.DeliveredQuantity}
        title="Delivered Quantity"
      />
    </div>
    <div className="w-50 d-flex flex-column gap-2">
      <FinanceDetailRow
        data={calculateRemainingQuantity(order)}
        title="Remaining Quantity"
      />
      <FinanceDetailRow
        data={order.CompletedQuantity}
        title="Completed Quantity"
      />
      <FinanceDetailRow
        data={order.BufferedQuantity}
        title="Allocated Quantity"
      />
    </div>
  </div>
);

const FinanceDetailCost = ({ order }) => {
  const offerPrice = order.Offer.OfferPrice || 0;

  const calculateProfit = (price = 0) =>
    (((order.Offer.OfferPrice - price) / order.Offer.OfferPrice) * 100).toFixed(
      2
    );

  const compareValue = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

  return (
    <div className="d-flex gap-4">
      <div className="w-50 d-flex flex-column gap-2">
        <FinanceDetailRow
          compare={compareValue(
            offerPrice,
            order.Product.VoiduPromotion?.EstimatedCost
          )}
          data={`€${order.Product.VoiduPromotion?.EstimatedCost?.toFixed(2)}`}
          title="Estimated Cost"
        />
        <FinanceDetailRow
          compare={compareValue(
            offerPrice,
            order.Product.VoiduPromotion?.EstimatedCost
          )}
          data={`${calculateProfit(
            order.Product.VoiduPromotion?.EstimatedCost
          )}%`}
          title="Estimated Profit"
        />
      </div>
      <div className="w-50 d-flex flex-column gap-2">
        <FinanceDetailRow
          invalid={order.Product.VoiduPromotion?.ActualCost <= 0}
          data={`€${order.Product.VoiduPromotion?.ActualCost?.toFixed(2)}`}
          title="Actual Cost"
        />
        <FinanceDetailRow
          invalid={order.Product.VoiduPromotion?.ActualCost <= 0}
          data={`${calculateProfit(order.Product.VoiduPromotion?.ActualCost)}%`}
          title="Actual Profit"
        />
      </div>
    </div>
  );
};

const FinanceDetailPricing = ({ order }) => {
  const offerPrice = order.Offer.OfferPrice || 0;
  const promotionPrice = order.Product.VoiduPromotion?.Price || 0;

  const isEqual = offerPrice === promotionPrice;
  const isOfferHigher = offerPrice > promotionPrice;
  const isOfferLower = offerPrice < promotionPrice;

  return (
    <div className="d-flex align-items-center gap-4">
      <div>
        <FinanceDetailRowHighlighted title="Offer Unit Price" />
      </div>
      <div className="flex-grow-1 d-flex justify-content-end align-items-center gap-2">
        <span className="cloudy-blue" style={{ fontSize: 14 }}>
          List Price:{" "}
        </span>
        <span
          className={classNames("cloudy-blue fs-5", !isEqual && "strikediag")}
        >
          €{promotionPrice.toFixed(2)}
        </span>
        <div
          className={classNames(
            "d-flex align-items-center gap-2 fs-4",
            isEqual && "equal-text-color",
            isOfferHigher && "up-text-color",
            isOfferLower && "down-text-color"
          )}
        >
          <i
            className={classNames(
              isEqual && "fas fa-equals",
              isOfferHigher && "fas fa-long-arrow-alt-up",
              isOfferLower && "fas fa-long-arrow-alt-down"
            )}
          />
          <span>€{offerPrice.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

const FinanceDetail = ({ order }) => {
  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (!order.Offer) {
    return <span>Order offer data is not provided.</span>;
  }

  if (!order.Product) {
    return <span>Order product data is not provided.</span>;
  }

  return (
    <div className="card p-3">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-2">
          <FinanceDetailPaymentStatusRow data={order} title="Payment Status" />
          <FinanceDetailRow
            data={order.IsBilled ? "YES" : "NO"}
            title="Is invoice generated?"
          />
          <FinanceDetailRow
            data={order.DontCreateInvoice ? "YES" : "NO"}
            title="Don't create invoice"
          />
        </div>
        <RowSeparator />
        <FinanceDetailQuantity order={order} />
        <RowSeparator />
        <FinanceDetailCost order={order} />
        <RowSeparator />
        <FinanceDetailPricing order={order} />
      </div>
    </div>
  );
};

export default FinanceDetail;
